import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldMercuria: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Mercuria?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_mercuria.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Mercuria?</h1>
          <h2>
            Not sure if pulling Mercuria is the right move? No worries! Check
            our review and guide for her!
          </h2>
          <p>
            Last updated: <strong>27/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="mercuria"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          While Mercuria is far from a must-pull, especially since she’s a
          6-star and there are many support options in the game, she is still a
          very strong support character. She has a unique mechanic that allows
          her Incantations to be considered one rank higher (doesn’t affect
          actual multipliers), which she uses to apply significant damage buffs.
          The sheer potential of her buffs makes her worth considering on that
          basis alone. She can also provide critical rate boosts and healing.
          Like many other support characters in the game, she is very good, but
          whether you should build her depends on what you want to achieve. If
          you plan to use Lucy, you will likely want Mercuria as they have
          near-perfect synergy with each other.
        </p>
        <SectionHeader title="Lore" />
        <p>
          When she was just a girl, Mercuria left the orphanage with nothing but
          a map and the clothes on her back. She’s been wandering the world ever
          since. She never stops too long, never gets too attached. She simply
          lets the universe guide her to her next destination. This is a journey
          destined to be walked alone, so few are given the opportunity to
          experience the gentleness behind her cold appearance.
        </p>
        <p>
          Mercuria’s lifestyle has fostered a deep internal spiritual
          connection, allowing her to connect and make use of the flowing energy
          of the universe. When she comes across someone surrounded by negative
          energy, she blesses them with a pendulum or herbs. But even without
          these tools, people flock to her, finding her presence alone to be a
          source of healing.
        </p>
        <p>
          Under the shining light of the mirror ball, Mercuria comes alive.
          Moved by the music, she often dances into the night. In fact, it’s how
          she makes her living. As she immerses herself in the beat, she becomes
          the focus of the crowd, attracting everyone’s attention with her
          vibrant movements. In these moments, it’s as if the city of San
          Francisco lives within her.
        </p>
        <SectionHeader title="Review" />
        <p>
          Mercuria is a 6 star rarity character from the Spirit afflatus who
          deals Mental damage and belongs to the Support class. Mercuria is one
          of the many support characters in the game who is extremely effective,
          and in this case, she comes with a unique new effect. Her Ultimate,
          “Morning Star for the Night”, is a mass attack that dispels buffs from
          enemies, deals damage, and grants allies a new status called [Rank Up]
          for 2 rounds. This status essentially increases your Incantation rank
          by 1; however, it doesn’t actually affect the Incantation’s effects
          and multipliers. The rank-up is purely for triggering effects that
          reference Incantation ranks. She also has self-synergy thanks to her
          passive ability, “Lengthy Journey”.
        </p>

        <p>
          She gains 8 stacks of [Cosmic Energy] when entering battle, which is
          important to note as it forms a crucial part of her gameplay. A P0,
          Cosmic Energy increases the party's damage by 2% per stack, up to a
          maximum of 25 stacks, and these stacks cannot be dispelled. This means
          that with a high number of stacks, you can deal a significant amount
          of damage. Additionally, with her first passive ability, aside from
          gaining stacks when entering battle, she also gains them whenever
          allies use Incantations. The higher the Incantation rank, the more
          [Cosmic Energy] stacks she will receive. This creates synergy with her
          own Incantation rank-up effects. It should be noted that [Cosmic
          Energy] gained only lasts 2 rounds, independent of each other.
        </p>
        <p>
          Another bonus of her passive is that she can also gain Moxie based on
          the number of allies with natural Afflatuses (Plant, Beast, Mineral,
          Star), up to 3. This is very flexible, allowing you to benefit from
          this when using any of those 4 types in your teams, which is likely.
          Her damage buffs and powerful passives don't end there, as her Insight
          III provides further benefits. If her stacks of [Cosmic Energy] are
          above 9, all allies gain 30% Crit DMG, and if 16 stacks or more are
          reached, all allies receive +20% Incantation Might. Furthermore, if
          allies have used two or more rank 3 Incantations, her own Moxie will
          increase.
        </p>
        <p>
          With that aside, her actual Incantations are pretty easy to
          understand. “Restless Souls” is a simple single-target attack that
          also restores the HP of the ally with the lowest HP. Her other
          Incantation, “Self Focusing” is a mass critical rate buff that also
          gains 4 [Cosmic Energy]. Mercuria is a fairly easy character to fit
          into most teams thanks to her high damage buff, and since most teams
          will have some critical-based characters, her crit rate buff will
          almost always be useful.
        </p>
        <p>
          One important thing to take note, and also one of Mercuria’s biggest
          downsides, is that at P0 she can be a bit clunky to play. To the point
          that you can get a more stable performance from her buffs if she
          doesn’t act at all. A core part of her gameplay being locked behind
          Portraits made her a quite controversial character. Regardless if you
          choose to invest on Portraits or follow the P0 and not acting patch,
          it’s possible to make good use of her kit if you keep these
          distinctions in mind.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    The unique mechanic in [Rank Up] has self-synergy and may be
                    useful in the future when more characters rely on
                    Incantation ranks.
                  </li>
                  <li>
                    She has extremely high buff values and can likely benefit
                    any team.
                  </li>
                  <li>Provides some healing thanks to Self Focusing.</li>
                  <li>
                    Great synergy with Lucy, Marcus, and pretty much anyone who
                    benefits from free buffs from a character who stands there
                    doing nothing.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Potential Overlap: Depending on your roster, other support
                    characters may already cover similar buffs or utilities, and
                    since she’s a 6-star, it’s hard to say she’s a must-pull.
                  </li>
                  <li>
                    Clunky gameplay at P0. She needs Portrait investment to not
                    self-sabotage her buff cycling.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>
          Unfortunately, Mercuria requires Portraits if you want her buff
          cycling to work properly. P1 has the highest cost-benefit as it lowers
          the requirements for her passive to grant her Moxie, but P5 is her
          best Portrait since it extends the duration of [Cosmic Energy], making
          it easier to keep it up at max stacks. If you like her but don't want
          to invest, you can keep her at P0 and just avoid acting.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>Great synergy overall with Lucy.</p>
        <p>
          Lucy is a high damage dealer whose follow-up attacks are counted as
          rank 2 Incantations, allowing Mercuria’s damage stacks to proc
          extremely quickly. Additionally, with Lucy already dealing significant
          damage on their own, they are a perfect match for each other. Marcus
          is the next obvious pick thanks to her self Empower Incantation
          passives, allowing her to use high rank incantations consistently with
          ease, thus also proccing Mercuria’s buffs.
        </p>
        <p>
          Mercuria will generally have an easy time fitting into most team
          setups, even if they don’t focus on crit. Her damage buffs will be
          sufficient to make her effective.
        </p>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Mercuria:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="starlight-wellspring"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="balance-please"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="her-second-life"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Resonance" />
        <h6>Level 7</h6>
        <StaticImage
          src="../../../images/reverse/generic/mercuria_r_1.webp"
          alt="Guides"
        />
        <h6>Level 9</h6>
        <StaticImage
          src="../../../images/reverse/generic/mercuria_r_2.webp"
          alt="Guides"
        />
        <h6>Level 10</h6>
        <StaticImage
          src="../../../images/reverse/generic/mercuria_r_3.webp"
          alt="Guides"
        />
        <br />
        <StaticImage
          src="../../../images/reverse/generic/mercuria_r_4.webp"
          alt="Guides"
        />
        <h5>Resonance Pattern Recommendations:</h5>
        <p>
          “Stupefaction” gives significantly more HP, ATK and Crit. Rate for
          Mercuria. All which are her most valuable stats.
        </p>
        <StaticImage
          src="../../../images/reverse/generic/mercuria_r_5.webp"
          alt="Guides"
        />
        <p>
          “Hyperphrenia” is the best choice if you want to ensure more survival
          to Mercuria while not handicapping her other main stats.
        </p>

        <StaticImage
          src="../../../images/reverse/generic/mercuria_r_6.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <p>
          A few suggestions are listed below. 3-People teams are highlighted,
          with the 4th slot added at the side to provide a 4-P variation of the
          same team or even insights on possible variations of the 3-P team.
        </p>
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            Lucy’s New Upgrade
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lucy"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="mercuria"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="kakania"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Any Units with Eureka</strong>
              </li>
              <li>
                This team is likely not the most optimal for Lucy at 3 members,
                but you can still fill the 4th slot with someone who has Eureka.
                Lucy’s follow-up attacks will count as Rank 2 Incantations,
                building up Mercuria’s damage buff extremely fast. This will
                make Lucy’s already great damage even higher. Kakania ensures
                the team’s survival while providing ATK support that doesn’t
                overlap with Mercuria and brings Lucy’s bursts to the next
                level. Any unit with Eureka could work for the last slot, like
                37, Getian, etc. Healers are also welcome if you’re struggling
                with survival.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            One More Dance<span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="marcus"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="mercuria"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Kakania, 6</strong>
              </li>
              <li>
                As mentioned in the team building section, Marcus’ consistent
                self Empower Incantation greatly helps to maximize the potential
                of Mercuria’s passives, which in return increases Marcus’
                explosive Incantation Might potential even further. Passive
                buffers like Kakania and 6 who don’t overlap with Mercuria and
                Vila serve as great options for the fourth slot.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Free Characters<span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="eagle"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="mercuria"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="la-source"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Sonetto, Lorelei</strong>
              </li>
              <li>
                Let's be honest, if you have Mercuria, you likely have more than
                just these characters. But assuming, somehow, that all you have
                is Mercuria and the free characters, voilà- you've got a team.
                For the most part, aside from raids and upper-tier content, this
                should clear most event story missions without much trouble.
                Mercuria's support works well with any damage dealer, and her
                critical rate boost can also benefit Eagle, a low-rarity,
                low-investment damage dealer.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldMercuria;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Mercuria? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Mercuria is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
